import "./contact_sidenav.pcss";

function closeSidenavComment() {
  const sidenavComment = document.querySelector("#sidenav_comment");
  sidenavComment.classList.remove("active");
}

function closeSidenavPhone() {
  const sidenavPhone = document.querySelector("#sidenav_phone");
  sidenavPhone.classList.remove("active");
}

document
  .querySelector("#sidenavCommentBtn")
  .addEventListener("click", function toggleSideComment() {
    closeSidenavPhone();
    const sidenavComment = document.querySelector("#sidenav_comment");

    if (sidenavComment.classList.contains("active")) {
      sidenavComment.classList.remove("active");
    } else {
      sidenavComment.classList.add("active");
    }
  });

document
  .querySelector("#sidenavPhoneBtn")
  .addEventListener("click", function toggleSidePhone() {
    closeSidenavComment();
    const sidenavPhone = document.querySelector("#sidenav_phone");
    if (sidenavPhone.classList.contains("active")) {
      sidenavPhone.classList.remove("active");
    } else {
      sidenavPhone.classList.add("active");
    }
  });

document
  .querySelector("#close_sidenavComment")
  .addEventListener("click", function toggleSidePhone() {
    closeSidenavComment();
  });
document
  .querySelector("#close_sidenavPhone")
  .addEventListener("click", function toggleSidePhone() {
    closeSidenavPhone();
  });
