import "./product_menu.pcss";

import Rails from "@rails/ujs";

const menuItems = document.querySelectorAll(".menu_item");
menuItems.forEach(function menuTeasers(menuItem) {
  menuItem.setAttribute(
    "style",
    `border-left: 18px solid ${menuItem.dataset.color}`
  );
  menuItem.addEventListener("mouseenter", event => {
    menuItems.forEach(function activeClass(item) {
      if (!item.matches(":hover")) {
        item.classList.remove("active");
      }
    });
    event.target.classList.add("active");
    Rails.ajax({
      url: "/teasers",
      type: "GET",
      data: `id=${event.target.dataset.item}`
    });
  });
});

document
  .getElementById("hamburger")
  .addEventListener("click", function toggleHamburger() {
    document.getElementById("hamburger").classList.toggle("change");
  });

// For tablet
document
  .getElementById("productNavItem")
  .addEventListener("click", function toggleDropdownClick() {
    const dropdown = document.getElementById("productNavDropdown");
    if (dropdown.classList.contains("show")) {
      dropdown.classList.remove("show");
    } else {
      dropdown.classList.add("show");
    }
  });

document
  .getElementById("productNavItem")
  .addEventListener("mouseover", function hoverItemShowDropdown() {
    document.getElementById("productNavDropdown").classList.add("show");
  });
document
  .getElementById("productNavDropdown")
  .addEventListener("mouseover", function hoverDropdownShowDropdown() {
    document.getElementById("productNavDropdown").classList.add("show");
  });
document
  .getElementById("productNavDropdown")
  .addEventListener("mouseleave", function leaveDropdownHideDropdown() {
    document.getElementById("productNavDropdown").classList.remove("show");
  });
document
  .getElementById("productNavItem")
  .addEventListener("mouseleave", function leaveItemHideDropdown() {
    document.getElementById("productNavDropdown").classList.remove("show");
  });
