import "./list.pcss";

import Rails from "@rails/ujs";

import $ from "jquery";

function addBottomLine() {
  const products = document.querySelectorAll(".product_card");
  const count = document.getElementById("list_count").dataset.count - 1;
  products.forEach(function addEachLine(product, index) {
    if (index !== count) {
      if (product.lastElementChild.classList.contains("card")) {
        const bottomLine = document.createElement("hr");
        product.appendChild(bottomLine);
      }
    }
  });
}

function loadMore() {
  let isLoading = false;
  const infiniteScroll = document.querySelectorAll(".infinite-scroll")[0];
  if (infiniteScroll.childNodes.length > 1) {
    $(window).on("scroll", function load() {
      const moreProductsUrl = $(".pagination .next a").attr("href");
      const thresholdPassed =
        $(window).scrollTop() > $(document).height() - $(window).height() - 800;
      if (!isLoading && moreProductsUrl && thresholdPassed) {
        isLoading = true;
        $.getScript(moreProductsUrl)
          .done(function line() {
            isLoading = false;
            addBottomLine();
          })
          .fail(function noLine() {
            isLoading = false;
          });
      }
    });
  }
}

// Submit search form when icon is clicked
if (document.querySelectorAll("#searchForm").length > 0) {
  document
    .getElementById("searchForm")
    .addEventListener("click", function startSearch() {
      document.getElementById("formForSearch").submit();
    });
}

if (document.querySelectorAll(".product_card").length > 0) {
  loadMore();
  addBottomLine();
}

document.addEventListener("change", event => {
  if (
    event.target.id === "material" ||
    event.target.id === "thickness" ||
    event.target.id === "height" ||
    event.target.id === "product_length"
  ) {
    let materialData = "material=";
    let thicknessData = "thickness=";
    let heightData = "height=";
    let productLengthData = "product_length=";
    if (document.getElementById("material") !== null) {
      materialData = `material=${document.getElementById("material").value}`;
    }
    if (document.getElementById("thickness") !== null) {
      thicknessData = `thickness=${document.getElementById("thickness").value}`;
    }
    if (document.getElementById("height") !== null) {
      heightData = `height=${document.getElementById("height").value}`;
    }
    if (document.getElementById("product_length") !== null) {
      productLengthData = `product_length=${
        document.getElementById("product_length").value
      }`;
    }
    const data = `${materialData}&${thicknessData}&${heightData}&${productLengthData}`;
    Rails.ajax({
      url: window.location.pathname + window.location.search,
      type: "GET",
      data: `${data}`,
      success() {
        addBottomLine();
        loadMore();
      },
      error() {}
    });
  }
});
