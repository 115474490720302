import "./download.pcss";

import Rails from "@rails/ujs";

if (document.querySelector("#documentationHeading")) {
  document
    .querySelector("#documentationHeading")
    .addEventListener("click", () => {
      Rails.ajax({
        url: "/documentation",
        type: "GET"
      });
    });
  document.querySelector("#dopHeading").addEventListener("click", () => {
    Rails.ajax({
      url: "/dop",
      type: "GET",
      data: "all=true",
      success() {
        const downloads = document.querySelectorAll(".downloadsDop");

        downloads.forEach(download => {
          download.addEventListener("click", () => {
            const downloadId = download.id;
            Rails.ajax({
              url: "/dop",
              type: "GET",
              data: `id=${downloadId}`
            });
          });
        });
      }
    });
  });
}
