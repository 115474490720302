import "./brand_row.pcss";

const brands = document.querySelectorAll(".brand_col");
brands.forEach(function setBrandImages(brand) {
  if (window.innerWidth < 768) {
    const pictureElement = brand.firstElementChild.lastElementChild;
    const source = pictureElement.src;
    pictureElement.src = source.replace("-logo", "");
  } else {
    brand.addEventListener("mouseenter", event => {
      const pictureElement = event.target.firstElementChild.lastElementChild;
      const source = pictureElement.src;
      pictureElement.src = source.replace("-logo", "");
    });
    brand.addEventListener("mouseleave", event => {
      const pictureElement = event.target.firstElementChild.lastElementChild;
      const source = pictureElement.src;
      pictureElement.src = `${source.slice(0, -4)}-logo${source.slice(-4)}`;
    });
  }
});
