import "./cookiebar.scss";

function showYoutubeVideos() {
  const videos = Array.from(document.getElementsByClassName("iframe"));
  if (videos.length > 0) {
    videos.forEach((video) => {
      video.src = video.dataset.src;
      video.classList.remove("d-none");
    });
  }
}

function hideYoutubePlaceholders() {
  const placeholders = Array.from(
    document.getElementsByClassName("iframe-placeholder")
  );
  if (placeholders.length > 0) {
    placeholders.forEach((placeholder) => {
      placeholder.classList.add("d-none");
    });
  }
}

function setCookies() {
  const acceptedCookies = localStorage.getItem("acceptedCookies");
  if (acceptedCookies === null) {
    document.getElementById("cookiebar").classList.remove("d-none");
  }
  if (acceptedCookies === "personal") {
    showYoutubeVideos();
    hideYoutubePlaceholders();
  }
}

function acceptedPersonalCookies() {
  localStorage.setItem("acceptedCookies", "personal");
  showYoutubeVideos();
  hideYoutubePlaceholders();
}

document.addEventListener("click", (event) => {
  if (event.target.className.includes("accept_cookies")) {
    document.getElementById("cookiebar").classList.add("d-none");

    if (document.querySelector('input[name="cookies"]:checked') != null) {
      const selectedOption = document.querySelector(
        'input[name="cookies"]:checked'
      ).value;
      if (selectedOption === "standard") {
        localStorage.setItem("acceptedCookies", "standard");
      } else {
        acceptedPersonalCookies();
      }
    } else {
      acceptedPersonalCookies();
    }
    if (document.getElementById("accept_cookies_submit") != null) {
      document
        .getElementById("accept_cookies_submit")
        .classList.remove("d-none");
    }
  }
});

window.addEventListener("load", function onWindowLoad() {
  if (window.location.href.indexOf("over_ceves_vergeer") > -1) {
    setCookies();
  }
});
