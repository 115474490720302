import "./footer.pcss";

import $ from "jquery";

$("#scrollToTop").each(function scrollToTopClicked() {
  $(this).click(function scrollToTop() {
    $("html,body").animate({ scrollTop: 0 }, "slow");
    return false;
  });
});
