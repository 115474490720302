import $ from "jquery";

import "bootstrap";

import "../stylesheets/application.scss";

import "components/topbar/topbar";

import "components/navbar/navbar";

import "components/contact_sidenav/contact_sidenav";

import "components/main/main";

import "components/product_menu/product_menu";

import "components/products_list/list";

import "components/product_columns/columns";

import "components/downloads/download";

import "components/projects/projects";

import "components/brand/brand_row";

import "components/footer/footer";

import "components/cookiebar/cookiebar";

require("@rails/ujs").start();

window.jQuery = $;
window.$ = $;
